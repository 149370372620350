<template>
  <div class="ShareBox">
    <div class="share-area sharePos">
      <ul>
        <li class="share-code ErWeiMa">
          <a class="share-tx-weChat"></a>
          <div
            id="layerWxcode"
            class="towdimcodelayer js-transition erWeiMaHover"
          >
            <div id="shareUrl"></div>
            <div>
              <p>打开微信</p>
              <p>扫一扫</p>
            </div>
          </div>
        </li>
      </ul>
      <textarea
        id="requestLink"
        style="position: absolute; top: 0; left: 0; opacity: 0; z-index: -10"
      ></textarea>
    </div>
  </div>
</template>

<script>
import * as Vue from "vue";
import $ from "jquery";
import QRCode from "qrcodejs2";

export default {
  name: "ShareBox",
  components: {},
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      shareDialog: true,
    });
    const handleClose = () => {
      state.shareDialog = false;
    };
    const OpenShare = () => {
      state.shareDialog = true;
    };

    return {
      state,
      handleClose,
      OpenShare,
    };
  },
  mounted() {
    (function ($, window, document, undefined) {
      //插件初始化
      function init(target, options) {
        var settings = $.extend({}, $.fn.socialShare.defaults, options);
        //初始化各个组件
        var $msb_main =
          "<a class='msb_main'><img title='分享' src='images/share_core_square.jpg'></a>";
        var $social_group =
          "<div class='social_group'>" +
          "<a target='_blank' class='msb_network_button weixin'>weixin</a>" +
          "<a target='_blank' class='msb_network_button sina'>sina</a>" +
          "<a target='_blank' class='msb_network_button tQQ'>tQQ</a>" +
          "<a target='_blank' class='msb_network_button qZone'>qZone</a>" +
          "<a target='_blank' class='msb_network_button douban'>douban</a>" +
          "</div>";
        $(target).append($msb_main);
        $(target).append($social_group);
        $(target).addClass("socialShare");

        //添加腾讯微博分享事件
        $(document).on("click", ".msb_network_button.tQQ", function () {
          tQQ(this, settings);
        });
        //添加QQ空间分享事件
        $(document).on("click", ".msb_network_button.qZone", function () {
          qZone(this, settings);
        });
        //添加新浪微博分享事件
        $(document).on("click", ".msb_network_button.sina", function () {
          sinaWeibo(this, settings);
        });
        //添加豆瓣分享事件
        $(document).on("click", ".msb_network_button.douban", function () {
          doubanShare(this, settings);
        });
        //添加微信分享事件
        $(document).on("click", ".msb_network_button.weixin", function () {
          weixinShare(this, settings);
        });
        $(document).on("click", ".msb_main", function () {
          if ($(this).hasClass("disabled")) return;
          var e = 500; //动画时间
          var t = 250; //延迟时间
          var r = $(this).parent().find(".msb_network_button").length; //分享组件的个数
          var i = 60;
          var s = e + (r - 1) * t;
          var o = 1;
          var a = $(this).outerWidth();
          var f = $(this).outerHeight();
          var c = $(this)
            .parent()
            .find(".msb_network_button:eq(0)")
            .outerWidth();
          var h = $(this)
            .parent()
            .find(".msb_network_button:eq(0)")
            .outerHeight();
          var p = (a - c) / 2; //起始位置
          var d = (f - h) / 2; //起始位置
          var v = (0 / 180) * Math.PI;
          if (!$(this).hasClass("active")) {
            $(this)
              .addClass("disabled")
              .delay(s)
              .queue(function (e) {
                $(this).removeClass("disabled").addClass("active");
                e();
              });
            $(this)
              .parent()
              .find(".msb_network_button")
              .each(function () {
                var n = p + (p + i * o) * Math.cos(v); //结束位置
                var r = d + (d + i * o) * Math.sin(v); //结束位置
                $(this)
                  .css({
                    display: "block",
                    left: p + "px",
                    top: d + "px",
                  })
                  .stop()
                  .delay(t * o)
                  .animate(
                    {
                      left: n + "px",
                      top: r + "px",
                    },
                    e
                  );
                o++;
              });
          } else {
            o = r;
            $(this)
              .addClass("disabled")
              .delay(s)
              .queue(function (e) {
                $(this).removeClass("disabled").removeClass("active");
                e();
              });
            $(this)
              .parent()
              .find(".msb_network_button")
              .each(function () {
                $(this)
                  .stop()
                  .delay(t * o)
                  .animate(
                    {
                      left: p,
                      top: d,
                    },
                    e
                  );
                o--;
              });
          }
        });
      }

      function replaceAPI(api, options) {
        api = api.replace("{url}", options.url);
        api = api.replace("{title}", options.title);
        api = api.replace("{content}", options.content);
        api = api.replace("{pic}", options.pic);

        return api;
      }

      function tQQ(target, options) {
        var options = $.extend({}, $.fn.socialShare.defaults, options);

        window.open(replaceAPI(tqq, options));
      }

      function qZone(target, options) {
        var options = $.extend({}, $.fn.socialShare.defaults, options);

        window.open(replaceAPI(qzone, options));
      }

      function sinaWeibo(target, options) {
        var options = $.extend({}, $.fn.socialShare.defaults, options);

        window.open(replaceAPI(sina, options));
      }

      function doubanShare(target, options) {
        window.open(
          replaceAPI(douban, $.extend({}, $.fn.socialShare.defaults, options))
        );
      }

      function weixinShare(target, options) {
        window.open(
          replaceAPI(weixin, $.extend({}, $.fn.socialShare.defaults, options))
        );
      }

      $.fn.socialShare = function (options, param) {
        if (typeof options == "string") {
          var method = $.fn.socialShare.methods[options];
          if (method) return method(this, param);
        } else init(this, options);
      };

      //插件默认参数
      $.fn.socialShare.defaults = {
        url: window.location.href,
        title: document.title,
        content: "",
        pic: "",
      };

      //插件方法
      $.fn.socialShare.methods = {
        //初始化方法
        init: function (jq, options) {
          return jq.each(function () {
            init(this, options);
          });
        },
        tQQ: function (jq, options) {
          return jq.each(function () {
            tQQ(this, options);
          });
        },
        qZone: function (jq, options) {
          return jq.each(function () {
            qZone(this, options);
          });
        },
        sinaWeibo: function (jq, options) {
          return jq.each(function () {
            sinaWeibo(this, options);
          });
        },
        doubanShare: function (jq, options) {
          return jq.each(function () {
            doubanShare(this, options);
          });
        },
        weixinShare: function (jq, options) {
          return jq.each(function () {
            weixinShare(this, options);
          });
        },
      };

      //分享地址
      var qzone =
        "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url={url}&title={title}&pics={pic}&summary={content}";
      var sina =
        "http://service.weibo.com/share/share.php?url={url}&title={title}&pic={pic}&searchPic=false";
      var tqq =
        "https://connect.qq.com/widget/shareqq/index.html?url={url}&showcount=0&desc={content}&summary=&title={title}&pics=&style=203&width=19&height=22";
      var douban =
        "http://www.douban.com/share/service?href={url}&name={title}&text={content}&image={pic}";
      var weixin = "http://qr.liantu.com/api.php?text={url}";
    })(jQuery, window, document);
    $(function () {
      var shareUrl = window.location.href; //这里注意请使用线上地址随便什么地址，本地是没有效果的
      var content = "";
      var title = "";
      var pic = "";
      document.getElementById("shareUrl").innerText = "";

      let index = window.location.href.lastIndexOf("id="); //获取id=后边的字符串
      let id = window.location.href.substring(
        index + 3,
        window.location.href.length
      );

      new QRCode(document.getElementById("shareUrl"), {
        text: shareUrl + `?id=${id}`,
        width: 100,
        height: 100,
      });
      // 设置要生成二维码的链接

      $(".share-tx-weChat")
        .mouseover(function () {
          $("#layerWxcode").show();
        })
        .mouseout(function () {
          $("#layerWxcode").hide();
        });

      $(".share-qq").on("click", function () {
        let currentUrl = encodeURIComponent(shareUrl);
        $(this).socialShare("tQQ", {
          url: currentUrl,
          title: title,
          content: content,
        });
      });
      $(".share-qzone").on("click", function () {
        let currentUrl = encodeURIComponent(shareUrl);
        $(this).socialShare("qZone", {
          url: currentUrl,
          title: title,
          content: content,
          pic: pic,
        });
      });
      $(".share-xl-weiBo").on("click", function () {
        let currentUrl = encodeURIComponent(shareUrl);
        $(this).socialShare("sinaWeibo", {
          url: currentUrl,
          title: title,
          content: content,
          pic: pic,
        });
      });
      $(".share-link-url").on("click", function () {
        var input = document.getElementById("requestLink");
        input.value = shareUrl; // 修改文本框的内容
        input.select(); // 选中文本
        document.execCommand("copy");
        window.message({
          message: "复制成功",
          type: "success",
        });
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.ErWeiMa:hover {
  .erWeiMaHover {
    display: block;
  }
}
.erWeiMaHover {
  display: none;
  //display: block;
  background: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  transform: translate(-150px, -20%);
  > div:nth-child(1) {
    padding: 10px;
  }
  p {
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #7d0303;
  }
}
.erWeiMaHover:after {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  position: relative;
  left: 120px;
  bottom: 90px;
  transform: rotate(90deg);
}
.ShareBox {
  width: 60px;
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  //background-color: white;
  cursor: pointer;

  > div {
    width: 100%;
    height: 100%;
    //margin-top: 5px;

    p {
      width: 100%;
      display: inline-block;
      font-size: 18px;
      font-weight: bolder;
      color: #7f0404;
      text-align: center;
    }
  }
}

ul > li {
  list-style-type: none;
  cursor: pointer;
}

.share-area {
  //padding: 0 20px 20px 20px;
  //padding-left: 10px;
  margin-right: 20px;
  //box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  padding-top: 10px;
  //background-color: white;
}

.share-area .label {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #7f0404;
  margin-bottom: 10px;
}

.share-area ul {
  overflow: hidden;
  display: inline-block;
}

.share-area ul li {
  float: left;
  //margin-right: 10px;
}

.share-area ul li a {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #7f0404;
  border-radius: 5px;
  background-color: white;
  margin: 5px 10px 10px 10px;
}

.share-area ul li a:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.share-tx-weChat {
  background-image: url("./image/weixin.png");
}

.share-qq {
  background-image: url("./image/QQ.png");
}

.share-qzone {
  background-image: url("./image/QQkongjian.png");
}

.share-xl-weiBo {
  background-image: url("./image/xinlangweibo.png");
}

.share-link-url {
  background-image: url("./image/lianjie.png");
}
</style>